<template>
  <div class="managingPeople hp100 bbox " v-loading="loading">
    <!-- 卡片视图 -->
    <!-- <el-card> -->
      <!-- 搜索-->
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col>
          <!-- <el-button plain class="add-column-btn bbox" @click="gotoDetail('')" type="primary">
                       <i class="el-icon-plus fw900 f16"></i> 新增</el-button> -->
        </el-col>
        <!-- <el-col style="width: 350px">
                    <div class="flex align-center justify-end header-search-box">
                        <el-input
                                placeholder="请输入运营商名称查询"
                                clearable
                                v-model="searchInput"
                                class="header-search-input ml10"
                        >
                            <el-button
                                    slot="append"
                                    icon="el-icon-search"
                                    class="textblue searchBtn"
                                    @click="search()"
                            ></el-button>
                        </el-input>
                    </div>
                </el-col> -->
      </el-row>
      <!-- 用户列表区域 -->
      <el-table
        :data="tableData"
        border
        fit
        :height="height"
        highlight-current-row
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column label="编号" type="index" width="50">
        </el-table-column>
        <el-table-column label="开票类型" align="center" prop="memberName">
          <template slot-scope="scope">
            <span>{{ scope.row.billType == 1 ? "企业" : "个人" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="公司名称" align="center" prop="memberName">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="税号" align="center" prop="memberCode">
          <template slot-scope="scope">
            <span>{{ scope.row.dutyNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="地址" align="center" prop="id">
          <template slot-scope="scope">
            <span>{{ scope.row.address }}</span>
          </template>
        </el-table-column>
        <el-table-column label="电话" align="center" prop="memberCode">
          <template slot-scope="scope">
            <span>{{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="开户行" align="center" prop="orderPrice">
          <template slot-scope="scope">
            <span>{{ scope.row.bankDeposit }}</span>
          </template>
        </el-table-column>
        <el-table-column label="开户行账号" align="center" prop="orderPrice">
          <template slot-scope="scope">
            <span>{{ scope.row.bankAccountNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="开户行" align="center" prop="orderPrice">
          <template slot-scope="scope">
            <span>{{ scope.row.bankDeposit }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" align="center" prop="orderPrice">
          <template slot-scope="scope">
            <span>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="金额" align="center" prop="orderPrice">
          <template slot-scope="scope">
            <span>{{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column label="邮箱" align="center" prop="orderPrice">
          <template slot-scope="scope">
            <span>{{ scope.row.email }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="orderPrice">
          <template slot-scope="scope">
            <span v-if="scope.row.createDt">{{
              scope.row.createDt | dataFormat
            }}</span>
            <span v-else>---</span>
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="查看详情"
              placement="bottom"
            >
              <i
                class="el-icon-edit textblue f16"
                @click="gotoDetail(scope.row.id)"
              ></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    <!-- </el-card> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight - 256, //表格高度
      value: "",
      tableData: [],
      loading: true,
      id: "",
      searchInput: "",
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 10,
        //查询条件
        condition: {
          id: "",
          operatorName: ""
        }
      },
      userlist: [],
      total: 0,
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false
    };
  },
  created() {
    // this.queryInfo.page=this.$route.query.page;
    //  this.queryInfo.limit=this.$route.query.limit;
    this.getListData();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    //条件查询
    search() {
      this.queryInfo.condition.operatorName = this.searchInput;
      this.getListData();
    },

    //状态改变事件
    // changeStatus(row) {

    //   var that=this;
    // //   alert()
    //  that.repairsStatus=1;
    //   that.$http.post("/repairsInfo/save", {repairsStatus:1,id:row.id}
    //   ).then(function (response) {
    //       that.$notify.success({
    //         title: "提示",
    //         message: "保存成功",
    //         showClose: true,
    //       });
    //     that.getListData();
    //   }).catch(function(error){
    //     //   console.log("---")
    //       that.$notify.info({
    //         title: "提示",
    //         message: "保存失败",
    //         showClose: true,
    //       });
    //   })

    // },
    judgeStatus(orderStatus) {
      if (orderStatus === 0) {
        return "未完成";
      } else if (orderStatus === 1) {
        return "未支付";
      } else if (orderStatus === 2) {
        return "已完成";
      } else {
        return "";
      }
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    // 监听排序
    sortChange() {},
    //跳转到详情
    gotoDetail(id) {
      this.$router.push({
        path: "/financeMag/applyFeeDetail",
        query: { id: id }
      });
    },

    // 删除用户
    // async del(id) {
    //   const confirmResult = await this.$confirm("确定删除吗?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).catch((err) => err);
    //   if (confirmResult === "confirm") {
    //        var that = this;
    //     that.$http.post("/repairsInfo/delete" ,{"id":id}).then(function (response) {
    //       that.$notify.success({
    //         title: "提示",
    //         message: "删除成功",
    //         showClose: true,
    //       });
    //       that.getListData();
    //     });
    //   }
    // },
    getListData() {
      // 获取列表
      var that = this;
      var data = that.queryInfo;
      //   console.log(data)
      that.$http
        .post("/memberBill/list", that.queryInfo)
        .then(function(response) {
          //   console.log("------")
          // console.log(response);
          if (response.data.code == 200) {
            // console.log("------")
            // console.log(response.data.data.data)
            that.total = response.data.data.count;
            //   that.queryInfo.page=response.data.page
            that.tableData = response.data.data.data;
          }
        });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    }
  }
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}
.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
    .el-select > .el-input {
      width: 200px;
    }
    // .header-search-box .header-search-input {
    //     width: 325px;
    // }
    input::placeholder {
      font-size: 12px;
    }
    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }
    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
</style>
